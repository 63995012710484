<template>
  <div class="bookmaster-wraper">
    <app-modal-confirm
      ref="apikey_modal_delete"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="deleteApiKey()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common_vn.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="news_modal_delete"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="deleteRestaurant()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common_vn.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >

      <form class="form-horizontal form-kyy-news">
        <div class="row">
          <div class="col-12">
            <div class="card card-body">
              <template v-if="idEditing">
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
                  <label class="col-xl-1 col-lg-2 col-sm-2 m-t-8">{{ entry.id }}</label>
                </div>
              </template>

              <!-- Category -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("category.name_other") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-select
                    name="kyy_category_name"
                    rules="required"
                    :options-data="categoryList"
                    v-model="entry.kyy_news_category_id"
                  />
                </div>
              </div>

              <!-- Title JA -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("news.title") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="kyy_news_title"
                    type="string128"
                    rules="required"
                    v-model="entry.title"
                  />
                </div>
              </div>

              <!-- Title VN -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("news.title_vn") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="kyy_news_title_vn"
                    type="string128"
                    rules="required"
                    @input="updateSlugField"
                    v-model="entry.title_vn"
                  />
                </div>
              </div>

              <!-- Content JA -->
              <div v-if="loading" class="form-group">
                <div class="row p-0 justify-content-between">
                  <div class="col-xl-8 col-lg-7 col-md-7 col-sm-8">
                    <label class="col-sm-10 pl-0">{{ $t("news.content") }}</label>
                  </div>
                  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-3">
                    <label class="col-sm-12 pl-0">{{ $t("news.preview") }}</label>
                  </div>
                </div>
                <div class="row p-0 justify-content-between">
                  <div class="col-xl-8 col-lg-7 col-md-7 col-sm-8">
                      <app-summer-note max-length="5120" richTextId="contentja"
                        @input="entry.content = $event" :val="entry.content">
                      </app-summer-note>
                  </div>
                  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-3">
                    <div class="html-preview">
                      <div v-html="entry.content"></div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Content VN -->
              <div v-if="loading" class="form-group">
                <div class="row p-0 justify-content-between">
                  <div class="col-xl-8 col-lg-7 col-md-7 col-sm-8">
                    <label class="col-sm-10 pl-0">{{ $t("news.content_vn") }}</label>
                  </div>
                  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-3">
                    <label class="col-sm-12 pl-0">{{ $t("news.preview_vn") }}</label>
                  </div>
                </div>
                <div class="row p-0 justify-content-between">
                  <div class="col-xl-8 col-lg-7 col-md-7 col-sm-8">
                      <app-summer-note max-length="5120" richTextId="contentvn"
                        @input="entry.content_vn = $event" :val="entry.content_vn">
                      </app-summer-note>
                  </div>
                  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-3">
                    <div class="html-preview">
                      <div v-html="entry.content_vn"></div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Thumbnail -->
              <div class="form-group row ">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("news.thumbnail") }}<span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-single-file-input accept="image/jpeg, image/png, image/jpg" name="kyy_news_thumbnail"
                    :imageSrc="entry.image_path"
                    @input="handlerUploadImage"
                    @updated_at="entry.data_updated_at = $event"
                    :id-editing="idEditing"
                    rules="required"
                    :allowedExtension="allowedExtension" >
                  </app-single-file-input>
                </div>
              </div>

              <!-- Slug -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common_vn.slug") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="slug"
                    type="string128"
                    v-model="entry.slug"
                  />
                </div>
              </div>

              <!-- Meta description -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common_vn.meta_description") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-textarea
                    name="meta_description"
                    type="string128"
                    v-model="entry.meta_description"
                  />
                </div>
              </div>
              <div class="card-footer row justify-content-end">
                <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
                  {{ $t('common_vn.cancel') }}
                </button>
                <button v-if="idEditing" type="button" :disabled="!valid && validated" class="btn btn-danger m-r-10" @click="handleDelete">
                  {{ $t('common_vn.delete') }}
                </button>
                <button v-if="idEditing" :disabled="!valid && validated" class="btn btn-primary m-r-10" @click="createOrUpdate" type="button">
                  {{  $t('common_vn.save') }}
                </button>
                <button v-else :disabled="!valid && validated" class="btn btn-primary m-r-10" type="button" @click="createOrUpdate" >
                  {{  $t('common_vn.create') }}
                </button>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import AppTextarea from "../../../components/forms/AppTextarea";
import AppSummerNote from "../../../components/forms/AppSummernote";
import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
import {ROUTES} from "@constants";
import {convertToSlug} from "@utils";
export default {
  name: "NewsCreateOrEdit",

  components: {
    AppTextarea,
    AppDateRangePicker,
    AppSummerNote,
  },

  data() {
    const id = this.$route.params.id
    return {
      entry: {
        id: null,
        kyy_news_category_id: null,
        title: '',
        title_vn: '',
        content: '',
        content_vn: '',
        slug: '',
        meta_description: '',
        image_path: null,
        image: null,
        data_updated_at: null,
      },
      categoryList: [],
      apiKeyId: null,
      idEditing: id,
      entryNameUpdate: "",
      startValidate : !!id,
      allowedExtension : ['image/jpeg', 'image/png', 'image/jpg'],
      loading: true,
    };
  },
  watch: {
  },

  mounted: async function () {
    let helperResponse = await this.$request.get(this.ENDPOINT.UI_HELPER_GET_NEWS_CATEGORIES);
    if (helperResponse.hasErrors()) {
      if (helperResponse.status === 'error'){
        this.__noticeError(this.$t(response.data.error_msg));
      }
    } else {
      this.categoryList = helperResponse.data.categories.map((category) => {
        return {
          id: category?.id,
          name: category?.name
        }
      })
    }

    if (this.idEditing) {
      this.startValidate = false;
      this.loading = false;
      let response = await this.$request.get(this.ENDPOINT.KYY_NEWS_EDIT(this.idEditing));
      if (response.hasErrors()) {
        if (response.status === 'error'){
          this.__noticeError(this.$t(response.data.error_msg));
        }
        await this.redirectDefaultValue()
      } else {
        this.entry = _.cloneDeep(response.data.data);
        this.entry.path = 'news/'
        this.entry.image_path = this.entry.image.url;
        this.entry.image_updated_at = this.entry.image.updated_at;
        this.loading = true;
        this.startValidate = true;
      }
    }
  },

  methods: {
    handlerUploadImage(data) {
      this.entry.image = data
      this.entry.image_path = data
      this.entry.image_updated_at = new Date().getTime()
    },
    confirmCancel()
    {
      if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.BOOK_MASTER_LIST) {
        this.__redirectBack()
      } else {
        this.redirectDefaultValue()
      }
    },
    async createOrUpdate() {
      this.startValidate = true;
      if (!await this.$refs.form_create_entry.validate()) {
        return;
      }
      let res = null;
      let msg = "";
      let params = _.cloneDeep(this.entry);
      if (this.idEditing) {
        res = await this.$request.patch(this.ENDPOINT.KYY_NEWS_EDIT(this.idEditing), params);
        msg = this.$t("common.msg update ok")
      } else {
        res = await this.$request.post(this.ENDPOINT.KYY_NEWS_CREATE, params);
        msg = this.$t("common.msg create ok")
      }
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        this.__noticeSuccess(msg);
        await this.redirectDefaultValue();
      }
    },

    async redirectDefaultValue() {
      await this.$router.push({
        name: this.ROUTES.ADMIN.KYY_NEWS_LIST, query: {}
      });
    },
    handleDelete() {
      this.$refs.news_modal_delete.show();
    },
    async deleteRestaurant() {
      const res = await this.$request.delete(this.ENDPOINT.KYY_NEWS_DELETE(this.idEditing));
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        await this.redirectDefaultValue();
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        this.$refs.news_modal_delete.hide();
      }
    },
    updateSlugField() {
      this.entry.slug = convertToSlug(this.entry.title_vn)
    }
  },
};
</script>
<style>
@media (min-width: 1200px) {
  .col-unit {
    flex: 0 0 14%;
  }
}

</style>
